import React from 'react';

import BlockySingleSelectRange from './BlockySingleSelectRange';
import DefaultSingleSelectRange from './DefaultSingleSelectRange';

const VariableSingleSelect = (props: any) => {
  const { variant, ...rest } = props;
  switch (variant) {
    case 'DAFT_BLOCKY':
      return <BlockySingleSelectRange {...rest} />;
    default:
      return <DefaultSingleSelectRange {...rest} />;
  }
};

export default VariableSingleSelect;
