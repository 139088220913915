import styled from 'styled-components';

import { DelayedSpinner } from '../../Toolkit/Spinner/DelayedSpinner';

export const FIRST_BREAK_POINT = '898px';

export const MainFlexWrapper = styled.div`
  ${({ isLoading }: { isLoading: boolean }) => isLoading && 'opacity: 0.15'};
  max-width: 1040px;
  margin: 0 auto;
  padding: 15px 0px 0px;

  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    display: flex;
    padding: 36px 8px 0px;
  }

  @media only screen and (min-width: 1056px) {
    padding: 36px 0px 0px;
  }
`;

export const CenteredSpinner = styled(DelayedSpinner)`
  position: fixed;
  right: calc(50% - 30px);
  top: 52%;
  width: 60px;
  z-index: 999;
`;

export const SearchResultsHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 16px 24px;
  width: calc(100% - 32px);

  @media only screen and (min-width: 704px) {
    margin-top: 0;
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 900px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

export const SearchPageListViewResultsHeader = styled.div<{
  isMobileRow: boolean;
}>`
  display: flex;
  flex-wrap: ${({ isMobileRow }) => (isMobileRow ? 'nowrap' : 'wrap')};
  margin: ${({ theme }) =>
    `${theme.spacing.S8} ${theme.spacing.M20} ${theme.spacing.M24} ${theme.spacing.M20}`};

  // At this point we drop the 'Sort' filter button
  @media only screen and (min-width: 704px) {
    flex-wrap: wrap;
  }

  // At this point we are considering it 'desktop' mode
  @media only screen and (min-width: 900px) {
    margin: 0;
    margin-bottom: ${({ theme }) => `${theme.spacing.M32}`};
  }
`;

export const SearchH1 = styled.h1`
  color: #333333;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  @media only screen and (min-width: 704px) {
    flex: 1;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
  }
`;
