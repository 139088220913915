import { SearchRequest } from '../../services/Filters/FilterTypes';

/**
 * Indicates if we should show the area counts in the autocomplete area filter.
 * We do not want the ad counts to display when:
 *  - Any filters are being applied to the search (with the exception of the adState = published as this
 * filter will soon be applied by default to searches).
 *  - If the geoSearchType (is null or STORED_SHAPES) e.g a predefined polygon search.
 *  (e.g like a map search etc)
 * @param searchRequest The current search request that has been performed on this page
 */
export const showAreaCounts = (searchRequest: SearchRequest): boolean => {
  const isPredefinedPolygonBasedSearch =
    !searchRequest?.geoFilter?.geoSearchType ||
    searchRequest?.geoFilter?.geoSearchType === 'STORED_SHAPES';
  return (
    !(
      searchRequest.section &&
      searchRequest.section.indexOf('valuation-tool') === 0
    ) &&
    searchRequest.ranges != null &&
    searchRequest.ranges.length === 0 &&
    searchRequest.terms != null &&
    searchRequest.terms.length === 0 &&
    isPredefinedPolygonBasedSearch &&
    isPublishedAdStateOnlyFilterApplied(searchRequest)
  );
};

const isPublishedAdStateOnlyFilterApplied = (
  searchRequest: SearchRequest,
): boolean => {
  const filters = searchRequest.andFilters.concat(searchRequest.filters);
  if (filters.length === 0) {
    return true;
  }

  if (filters.length === 1) {
    const filter = filters[0] as any;
    if (filter.name === 'adState') {
      return filter.values.length === 1 && filter.values[0] === 'published';
    }
  }
  return false;
};
