import React from 'react';

import { AutocompleteAPI } from '../../api';
import VariableDropDownRange from '../../components/Filters/DropDownRange/VariableDropDownRange';
import VariableListMultiSelect from '../../components/Filters/ListMultiSelect/VariableListMultiSelect';
import VariableSingleSelect from '../../components/Filters/SingleSelect/VariableSingleSelect';
import VariableSingleSelectRange from '../../components/Filters/SingleSelectRange/VariableSingleSelectRange';
import { AutocompleteConnectionHelper } from '../../components/MultiInputTextSelect/AutocompleteConnectionHelper';
import { CountyAreaFilter } from '../../components/MultiInputTextSelect/CountyAreaFilter';
import { TermsFilter } from '../../components/TermsFilter/TermsFilter';

import { UIFilters } from './FilterTypes';

export const filters: UIFilters = {
  ListSingleSelect: (props: any) => <VariableSingleSelect {...props} />,
  ListMultiSelect: (props: any) => <VariableListMultiSelect {...props} />,
  DropDownRange: (props: any) => <VariableDropDownRange {...props} />,
  SingleSelectRange: (props: any) => <VariableSingleSelectRange {...props} />,
  LocationInputBox: (props: any) => (
    <AutocompleteConnectionHelper
      apiCall={AutocompleteAPI.suggestions}
      key={props.key}
      path={props.options.currentPath}
    >
      {({ getAutocompleteProps }: any) => (
        <CountyAreaFilter {...getAutocompleteProps(props)} />
      )}
    </AutocompleteConnectionHelper>
  ),
  TextInput: (props: any) => <TermsFilter {...props} />,
};
