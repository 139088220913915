import { Component } from 'react';

import { filters } from './Filters';

type FilterManagerProps = {
  filterAPI: any;
  onFiltersChange: (query: any) => void;
  alternativeStyle?: boolean;
  showLabel?: boolean;
  appendToId?: string;
  /**
   * In some cases we need to override the variant
   * to change the design.
   */
  variantOverride?: string;
  options?: any;
};

class FilterManager extends Component<FilterManagerProps, any> {
  render() {
    const { appendToId, filterAPI, variantOverride, options = {} } = this.props;
    return filterAPI.map(
      ({
        id,
        filterType,
        name,
        displayName,
        variant,
        values,
        searchQueryGroup,
        currentValue,
        ...restOfValues
      }: any): JSX.Element | null => {
        if (filters[filterType.name]) {
          const shouldHideLabel = name.indexOf('Price') !== -1;
          return filters[filterType.name]({
            searchQueryGroup,
            name,
            displayName,
            filterType,
            currentValue,
            values,
            id: appendToId ? `${name}${appendToId}` : name,
            alternativeStyle: this.props.alternativeStyle,
            showLabel: this.props.showLabel,
            key: id,
            onChange: this.props.onFiltersChange,
            variant: variantOverride ? variantOverride : variant,
            options: { ...options[filterType.name], ...restOfValues },
            noLabelInDisplayText: shouldHideLabel,
          });
        }
        return null;
      },
    );
  }
}

export default FilterManager;
