import React from 'react';

import styled from 'styled-components';

import { SingleSelectRangeFilter } from '../components-filter/SingleSelectRange/SingleSelectRange';
import { BlockySelect, BlockySelectCSS } from '../Styles/SharedFilterStyles';

const StyledSingleSelectFilter = styled(SingleSelectRangeFilter)<any>`
  ${BlockySelectCSS};
`;

const DefaultSingleSelectFilter = (props: any) => (
  <div>
    <BlockySelect>
      <StyledSingleSelectFilter {...props} />
    </BlockySelect>
  </div>
);

export default DefaultSingleSelectFilter;
