import React from 'react';

import styled from 'styled-components';

import { CDN_URL_STATIC_DIRECTORY } from '../../helpers/utils';
import { FilterRequestAPI } from '../../services/Filters/FilterTypes';
import { hasValidValue } from '../../services/Search/SearchUtils';

import {
  NewButton,
  NewButtonSizes,
  NewButtonTypes,
} from '../Toolkit/NewButton/NewButton';

type NoResultsProps = {
  filterAPI: FilterRequestAPI[];
  resetFilter: (filter: FilterRequestAPI) => void;
  tryAgainMessage?: string;
  hideImage?: boolean;
  buttonType: 'TERTIARY' | 'SECONDARY';
  smallCard?: boolean;
};

const StyledButton = styled(NewButton)`
  margin-top: 8px;
  :first-of-type {
    margin-left: -8px;
  }
`;

const HouseSearchImage = styled.img`
  display: block;
  max-width: 134px;
  @media only screen and (min-width: 704px) {
    max-width: 170px;
  }
  margin: 30px auto;
`;

const Container = styled.div<{ smallCard?: boolean }>`
  text-align: center;
  color: #333333;
  padding: 0 24px;
  ${({ smallCard }) =>
    smallCard &&
    `
  width: 100vw;
  padding: 0;
  `}
`;

const HeaderText = styled.p`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;

  @media only screen and (min-width: 800px) {
    font-size: 20px;
    margin-bottom: 8px;
  }
`;

const SubText = styled.p`
  margin-bottom: 8px;
  font-size: 14px;

  @media only screen and (min-width: 800px) {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;
const StyledRemoveFilterButton = styled.div`
  overflow: auto;
  white-space: nowrap;
`;

const ZeroResultsButtons = (props: NoResultsProps) => {
  const { filterAPI } = props;
  const buttons = filterAPI.map((filter: FilterRequestAPI) => {
    const currentValue = filter?.currentValue;
    let filterObj: any;
    if (typeof currentValue === 'string') {
      filterObj = {
        currentValue,
        name: filter.name,
        searchQueryGroup: filter.searchQueryGroup,
      };
    } else {
      filterObj = {
        searchQueryGroup: filter.searchQueryGroup,
        ...currentValue,
      };
    }

    return hasValidValue(filterObj) ? (
      <StyledButton
        key={filter.name}
        buttonSize={NewButtonSizes.X_SMALL}
        buttonType={NewButtonTypes[props.buttonType]}
        onClick={() => props.resetFilter(filter)}
        buttonText={filter.displayName}
        iconName="CLOSE"
        data-testid={`zero-results-btn-${filter.name}`}
      />
    ) : null;
  });
  return <div>{buttons}</div>;
};

const ZeroResults = (props: NoResultsProps) => {
  const { hideImage, smallCard, tryAgainMessage } = props;

  return (
    <Container smallCard={smallCard}>
      {!hideImage && (
        <HouseSearchImage
          data-testid="zero-results-img"
          src={`${CDN_URL_STATIC_DIRECTORY}/static/images/house-search.png`}
          alt=""
        />
      )}
      <HeaderText>We didn't find any properties</HeaderText>
      <SubText>
        {tryAgainMessage ? (
          tryAgainMessage
        ) : (
          <>Try removing some filters or searching in more areas</>
        )}
      </SubText>
      {smallCard ? (
        <StyledRemoveFilterButton>
          <ZeroResultsButtons {...props} />
        </StyledRemoveFilterButton>
      ) : (
        <ZeroResultsButtons {...props} />
      )}
    </Container>
  );
};

export default ZeroResults;
