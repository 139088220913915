import { forceIntoArray } from '../../helpers/utils';

import { AreaTag } from '../../types';
import {
  FilterQuery,
  FilterRequestAPI,
  ListMultiSelectQuery,
  PagingQuery,
  RangeQuery,
  SearchRequest,
  TermsQuery,
  SearchFilters,
  ListSingleSelect,
  ListMultiSelect,
  FilterSearchQuery,
} from '../Filters/FilterTypes';
import SearchSEOParams from '../Url/SearchSEOParams';

const DEFAULT_PAGING_SIZE = '20';

export const hasValidValue = (filter: FilterRequestAPI) => {
  switch (filter.searchQueryGroup) {
    case 'filters':
      const filtersFilter = filter as FilterQuery | ListMultiSelectQuery;
      return Boolean(
        filtersFilter.values &&
          filtersFilter.values.length &&
          filtersFilter.values[0],
      );
    case 'paging':
      const pagingFilter = filter as PagingQuery;
      return Boolean(pagingFilter.from);
    case 'ranges':
      const rangeFilter = filter as RangeQuery;
      return Boolean(rangeFilter.from || rangeFilter.to);
    case 'terms':
      const termsFilter = filter as TermsQuery;
      return Boolean(termsFilter.currentValue);
    case 'andFilters':
      const andFilter = filter as ListMultiSelectQuery;
      return Boolean(andFilter.values);
    default:
      return false;
  }
};

const extractActiveFilters = (
  searchFilters: FilterRequestAPI[] = [],
): FilterRequestAPI[] => {
  let returnValue: FilterRequestAPI[] = [];
  searchFilters.forEach((filter: FilterRequestAPI) => {
    const currentValue = filter.currentValue;
    let filterObj: any;
    if (
      typeof currentValue === 'string' ||
      (currentValue && currentValue.length > 0)
    ) {
      filterObj = {
        currentValue,
        name: filter.name,
        searchQueryGroup: filter.searchQueryGroup,
      };
    } else {
      filterObj = {
        searchQueryGroup: filter.searchQueryGroup,
        ...currentValue,
      };
    }

    if (
      hasValidValue(filterObj) ||
      (filterObj.currentValue && filterObj.currentValue.length > 0)
    ) {
      returnValue = [filterObj, ...returnValue];
    }
  });

  return returnValue;
};

const mapLocationNamesToIds = (locationNames: string[], areaItems: AreaTag[]) =>
  locationNames.map((locationName: string) => {
    const foundAreaItem = areaItems.find(
      (areaItem) => locationName === areaItem.displayValue,
    );

    return foundAreaItem && foundAreaItem.id;
  });

export const getSectionFromQueryParams = (query: string) => {
  const queryParams = SearchSEOParams.reconcileSEOMultiParamsFromURL(query);

  let section = '';

  if (queryParams.section && queryParams.section.length > 0) {
    section = queryParams.section;
    delete queryParams.section;
  }
  return section;
};

const prePopulateQueryWithDefaultFilterValues = (
  searchRequest: SearchRequest,
  filters: SearchFilters[],
): SearchRequest => {
  const updatedSearchRequest = searchRequest;
  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i] as any;
    const searchQueryGroup = filter.searchQueryGroup;
    // toggle values should not be prepopulated. As they only return one value always.
    // e.g the value to be applied when the toggle is selected
    if (filter.filterType.name === 'Toggle') {
      continue;
    }

    switch (searchQueryGroup) {
      case 'andFilters':
        const filterQueryAnd = filters[i] as unknown as
          | ListSingleSelect
          | ListMultiSelect;
        const firstValueAnd = filterQueryAnd.values[0].value;
        if (firstValueAnd) {
          updatedSearchRequest.andFilters.push({
            name: filterQueryAnd.name,
            values: [firstValueAnd],
          });
        }
        break;
      case 'filters':
        const filterQuery = filters[i] as unknown as
          | ListSingleSelect
          | ListMultiSelect;
        const firstValue = filterQuery.values[0].value;
        if (firstValue) {
          updatedSearchRequest.filters.push({
            name: filterQuery.name,
            values: [firstValue],
          });
        }
        break;
      case 'ranges':
        break;
      default:
        break;
    }
  }
  return updatedSearchRequest;
};

const buildInitialSearchQueryFromQueryParamsAndFilters = (
  query: string,
  /**
   * Provides data which maps from the URL params to the necessary search query params
   * (eg. Location search query requires `id`, but URL query is `displayValues`)
   */
  locationList: AreaTag[],
  filtersRequest: FilterRequestAPI[],
  filters: SearchFilters[],
  section: string,
) => {
  const queryParams = SearchSEOParams.reconcileSEOMultiParamsFromURL(query);

  let searchQuery: SearchRequest = {
    section,
    filters: [],
    andFilters: [],
    ranges: [],
    paging: {},
    geoFilter: {},
    terms: '',
  };
  searchQuery = prePopulateQueryWithDefaultFilterValues(searchQuery, filters);

  // Set paging search query value
  if (queryParams.from) {
    searchQuery.paging = {
      from: queryParams.from || '0',
      pageSize: DEFAULT_PAGING_SIZE,
    };
  } else {
    searchQuery.paging = {
      from: '0',
      pageSize:
        queryParams.showMap && queryParams.showMap === 'true'
          ? 50
          : DEFAULT_PAGING_SIZE,
    };
  }

  // Set sort search query value
  if (queryParams.sort) {
    searchQuery.sort = queryParams.sort || 'bestMatch';
  }
  // Set agentIds search query value to filters searchQuery
  if (queryParams.agentIds) {
    searchQuery.filters = replaceFilterValueInSearchQuery(
      searchQuery.filters,
      'agentIds',
      queryParams,
    );
  }

  for (const param of Object.keys(queryParams)) {
    const split = param.split('_');
    const queryName = split[0];

    let searchQueryGroup = '';

    // Get searchQueryGroup value for query by comparing queryName to filters
    for (let i = 0; i < filtersRequest.length; i += 1) {
      if (filtersRequest[i].name === queryName) {
        searchQueryGroup = filtersRequest[i].searchQueryGroup;
        break;
      }
    }

    switch (searchQueryGroup) {
      case 'andFilters':
        searchQuery.andFilters = replaceFilterValueInSearchQuery(
          searchQuery.andFilters,
          queryName,
          queryParams,
        );
        break;
      case 'filters':
        searchQuery.filters = replaceFilterValueInSearchQuery(
          searchQuery.filters,
          queryName,
          queryParams,
        );
        break;
      case 'geoFilter':
        handleGeoFilter(queryParams, queryName, searchQuery, locationList);
        break;
      case 'ranges':
        if (split.length > 1) {
          searchQuery.ranges.push(
            getRangeFilterSearchParamsFromUrlQueryParams(searchQuery.ranges, {
              name: param,
              value: queryParams[param],
            }),
          );
        }
        break;
      case 'terms':
        searchQuery.terms = queryParams[queryName] || '';
        break;
      default:
      // ignore others
    }
  }
  return searchQuery;
};

const handleGeoFilter = (
  queryParams: any,
  queryName: string,
  searchQuery: SearchRequest,
  locationList: AreaTag[],
) => {
  const isGeoSearchTypeValid =
    queryParams?.geoSearchType === 'BBOX' ||
    queryParams?.geoSearchType === 'CUSTOM_SHAPES' ||
    queryParams?.geoSearchType === 'BBOX_CUSTOM_SHAPES';

  if (isGeoSearchTypeValid) {
    const {
      bottom,
      geoSearchType,
      left,
      location,
      mapView,
      polygon,
      right,
      showMap,
      top,
    } = queryParams;
    searchQuery.geoFilter = {
      bottom,
      geoSearchType,
      left,
      location,
      mapView,
      right,
      polygon,
      showMap,
      top,
    };
    if (queryParams.polygon) {
      queryParams.polygon = JSON.parse(queryParams.polygon);
    }
    searchQuery.geoFilter = queryParams;
  } else {
    searchQuery.geoFilter = getGeoFilterSearchQueryFromUrlQueryParams(
      { name: queryName, value: queryParams[queryName] },
      locationList,
      queryParams.radius,
    );
  }
};

const replaceFilterValueInSearchQuery = (
  filterValues: ListMultiSelectQuery[] | FilterQuery[],
  queryName: string,
  queryParams: any,
): ListMultiSelectQuery[] | FilterQuery[] => {
  const updatedArray = filterValues.filter((item) => item.name !== queryName);
  updatedArray.push({
    values: forceIntoArray(queryParams[queryName]),
    name: queryName,
  });
  return updatedArray;
};

const getGeoFilterSearchQueryFromUrlQueryParams = (
  geoFilterQueryParam: { name: string; value: string },
  locationList: AreaTag[],
  radiusParamValue: string,
) => {
  const values = forceIntoArray(geoFilterQueryParam.value);
  if (geoFilterQueryParam.name === 'location') {
    const indexOfDefaultValue = Math.max(
      values.indexOf('ireland'),
      values.indexOf('worldwide'),
    );
    if (indexOfDefaultValue !== -1) {
      values.splice(indexOfDefaultValue, 1);
    }

    let storedShapeIds = mapLocationNamesToIds(values, locationList);

    if (storedShapeIds.length === 1 && radiusParamValue) {
      storedShapeIds = [`${storedShapeIds[0]}_${radiusParamValue}`];
    }

    if (values.length) {
      return {
        storedShapeIds,
        geoSearchType: 'STORED_SHAPES',
      };
    }
  }
  return {
    storedShapeIds: [],
  };
};

const getRangeFilterSearchParamsFromUrlQueryParams = (
  ranges: any,
  rangeQueryParam: { name: string; value: string },
) => {
  let from = '';
  let to = '';
  const split = rangeQueryParam.name.split('_');
  const queryName = split[0];
  const rangeSide = split[1];

  if (rangeSide !== 'from' && rangeSide !== 'to') {
    return ranges;
  }

  const indexOfCurrentRange = ranges.findIndex(
    (range: any) => range.name === queryName,
  );

  if (rangeSide === 'from') {
    from = rangeQueryParam.value;
  }

  if (rangeSide === 'to') {
    to = rangeQueryParam.value;
  }

  if (indexOfCurrentRange < 0) {
    return { from, to, name: queryName };
  }

  const currentRange = ranges.splice(indexOfCurrentRange, 1)[0];

  if (rangeSide === 'from') {
    currentRange.from = from;
  }

  if (rangeSide === 'to') {
    currentRange.to = to;
  }

  return currentRange;
};

const getAgentIdQueryFilterFromUrlQueryParams = (
  searchQuery: SearchRequest,
  listings: any[],
) => {
  let queryFilters: FilterSearchQuery[] = [];

  queryFilters =
    searchQuery &&
    searchQuery.filters.reduce(
      (filters: FilterSearchQuery[], query: FilterSearchQuery) => {
        if (query.name === 'agentIds') {
          const values = query.values.map((item: any) => {
            const id = parseInt(item);
            const displayValue = listings.find(
              (item: any) => item.listing.seller.sellerId === id,
            );
            return {
              name: query.name,
              displayName: displayValue
                ? displayValue.listing.seller.branch
                : 'Agent',
              values: [item],
              currentValue: { values: item },
              searchQueryGroup: 'filters',
            };
          });
          filters.push(...values);
        }
        return filters;
      },
      [],
    );
  return queryFilters;
};
// Note that this is only from 'recent' section not all properties
// Used to calculate the <lastmod> property for the recent section sitemap
export const getLatestFromRecentSection = (
  from: number,
  pageSize: number,
): SearchRequest => {
  return {
    filters: [],
    andFilters: [],
    ranges: [],
    paging: {
      from: from,
      pageSize: pageSize,
    },
    section: 'recent',
    geoFilter: {},
    terms: '',
    sort: 'publishDateDesc',
  };
};

export const getActiveAndSaleAgreedAdIdsForSitemap = (
  from: number,
  pageSize: number,
): SearchRequest => {
  return {
    filters: [
      {
        name: 'adState',
        values: ['published', 'sale-agreed'],
      },
    ],
    andFilters: [],
    ranges: [],
    paging: {
      from: from,
      pageSize: pageSize,
    },
    geoFilter: {},
    terms: '',
    sort: 'publishDateDesc',
  };
};

export const getActiveIdsForSitemapRssFeed = (
  pageSize: number,
): SearchRequest => {
  return {
    filters: [
      {
        name: 'adState',
        values: ['published'],
      },
    ],
    andFilters: [],
    ranges: [],
    paging: {
      from: 0,
      pageSize: pageSize,
    },
    geoFilter: {},
    terms: '',
    sort: 'publishDateDesc',
  };
};

export {
  buildInitialSearchQueryFromQueryParamsAndFilters,
  getAgentIdQueryFilterFromUrlQueryParams,
  extractActiveFilters,
};
